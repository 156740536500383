import { IDistributeCourseModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
  Autocomplete,
  Slider,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Collapse,
} from "@mui/material";
import axios from "axios";

import {
  distributeRegularCourseSchema,
  distributeInstantCourseSchema,
} from "../schemas/distributeCourse";

import { track } from "@amplitude/analytics-browser";
import { useEffect, useState } from "react";
import moment, { max } from "moment";
import { DatePicker } from "@mui/x-date-pickers";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IDropdownOption } from "../types/common";

import Actions from "../components/Modal/Actions";

import Calendar from "../components/Icons/Calendar";
import Clipboard from "../components/Icons/Clipboard";
import { TabContext, TabPanel } from "@mui/lab";
import InfoCircle from "../components/Icons/InfoCircle";
import { ICourseDistributionRequest } from "../types/distributeCourse";
import { CourseTrainingType } from "../constants/courseTrainingType";
import { toast } from "react-toastify";

const DistributeCourse: React.FunctionComponent<
  IDistributeCourseModalProps
> = ({ id, initialValues, callback }) => {
  const dispatch = useAppDispatch();

  const [type, setType] = useState<"regular" | "instant">("regular");

  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const [courses, setCourses] = useState<IDropdownOption[]>([]);
  const [teams, setTeams] = useState<IDropdownOption[]>([]);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    watch,
  } = useForm<
    ICourseDistributionRequest & {
      mandatory: boolean;
    }
  >({
    defaultValues: {
      questionCategoryId: initialValues?.questionCategoryId || undefined,
      channelId: initialValues?.channelId || undefined,
      lessonTime: initialValues?.lessonTime || "12:00:00",
      startDate: initialValues?.startDate
        ? moment.utc(initialValues.startDate).startOf("day").toDate()
        : moment.utc().startOf("day").toDate(),
      endDate: initialValues?.endDate
        ? moment.utc(initialValues.endDate).endOf("day").toDate()
        : undefined,
      correctAnswersCriteria: initialValues?.correctAnswersCriteria || 80,
      lessonCount: initialValues?.lessonCount || 0,
      mandatory: initialValues
        ? initialValues.courseTrainingType === CourseTrainingType.Mandatory
        : false,
      shuffle: initialValues?.shuffle,
      monday: initialValues?.monday || false,
      tuesday: initialValues?.tuesday || false,
      wednesday: initialValues?.wednesday || false,
      thursday: initialValues?.thursday || false,
      friday: initialValues?.friday || false,
      saturday: initialValues?.saturday || false,
      sunday: initialValues?.sunday || false,
    },
    resolver: yupResolver(
      type === "regular"
        ? distributeRegularCourseSchema
        : distributeInstantCourseSchema
    ),
  });

  const onSubmit = handleSubmit((values) => {
    setIsSubmitting(true);

    const data: ICourseDistributionRequest & {
      id?: number;
    } = {
      ...values,
      id: initialValues?.id,
      courseTrainingType:
        type === "regular"
          ? values.mandatory
            ? CourseTrainingType.Mandatory
            : CourseTrainingType.Regular
          : CourseTrainingType.Instant,
      answersGivenCriteria: 100,
    };

    axios
      .post(
        initialValues
          ? "/CourseDistributions/UpdateCourseDistribution"
          : "/CourseDistributions/CreateCourseDistribution",
        data
      )
      .then((res) => {
        toast("Course saved successfully", {
          type: "success",
        });
        callback?.();
        handleClose();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  });

  const onDelete = () => {
    if (!initialValues) {
      return;
    }

    setIsDeleting(true);

    axios
    .post
    (
      "/CourseDistributions/DeleteCourseDistribution", null, {
        params: {
          id: initialValues.id,
          courseTrainingType: initialValues.courseTrainingType
      }
    })
    .then((res) => {
      toast("Course deleted successfully", {
        type: "success",
      });
      callback?.();
      handleClose();
    })
    .finally(() => {
      setIsDeleting(false);
    });


  };

  useEffect(() => {
    setIsDropdownLoading(true);
    setType(initialValues?.courseTrainingType === CourseTrainingType.Instant ? "instant" : "regular");
    axios
      .get<{
        questionCategories: IDropdownOption[];
        channels: IDropdownOption[];
      }>("/Challenge/GetEditOptions")
      .then((res) => {
        setCourses(res.data.questionCategories);
        setTeams(res.data.channels);
      })
      .finally(() => {
        setIsDropdownLoading(false);
      });
  }, []);

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  useEffect(() => {
    track("OpenedContentCreate");
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <form onSubmit={onSubmit}>
        <DialogTitle>Distribute a course</DialogTitle>
        <DialogContent style={{ overflow: "visible" }}>
          <Stack spacing={2}>
            <Controller
              name="questionCategoryId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  loading={isDropdownLoading}
                  options={courses}
                  value={courses.find((x) => x.id === field.value) || null}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course"
                      error={!!errors.questionCategoryId}
                      helperText={errors.questionCategoryId?.message}
                    />
                  )}
                  onChange={(e, value) => field.onChange(value?.id)}
                />
              )}
            />
            <Controller
              name="channelId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  loading={isDropdownLoading}
                  options={teams}
                  value={teams.find((x) => x.id === field.value) || null}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Team"
                      error={!!errors.channelId}
                      helperText={errors.channelId?.message}
                    />
                  )}
                  onChange={(e, value) => field.onChange(value?.id)}
                />
              )}
            />

            <TabContext value={type}>
              <Stack direction="row" spacing={2}>
                <Button
                  variant={type === "regular" ? "contained" : "outlined"}
                  fullWidth
                  startIcon={
                    <Calendar width={50} height={50} color="#F47923" />
                  }
                  onClick={() => setType("regular")}
                  style={{
                    textAlign: "left",
                    lineHeight: 1.5,
                  }}
                >
                  Schedule regular lessons
                </Button>
                <Button
                  variant={type === "instant" ? "contained" : "outlined"}
                  fullWidth
                  startIcon={
                    <Clipboard width={50} height={50} color="#F47923" />
                  }
                  onClick={() => setType("instant")}
                  style={{
                    textAlign: "left",
                    lineHeight: 1.5,
                  }}
                >
                  Publish course instantly
                </Button>
              </Stack>
              <TabPanel value="regular">
                <Stack spacing={1}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Controller
                      name="lessonTime"
                      control={control}
                      render={({ field }) => (
                        <FormControl>
                          <InputLabel id="time" required>
                            Lesson time
                          </InputLabel>
                          <Select
                            labelId="time"
                            value={field.value.toString()}
                            onChange={(event: SelectChangeEvent) => {
                              field.onChange(event.target.value);
                            }}
                            sx={{
                              minWidth: 120,
                            }}
                            label="Lesson time"
                          >
                            {Array.from({ length: 24 }, (_, index) => (
                              <MenuItem
                                key={index}
                                value={`${index
                                  .toString()
                                  .padStart(2, "0")}:00:00`}
                              >
                                {index.toString().padStart(2, "0")}:00
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    <Stack>
                      <Typography>Receive lessons on</Typography>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        {moment.weekdays().map((day, index) => (
                          <Controller
                            // @ts-ignore
                            name={day.toLowerCase()}
                            control={control}
                            render={({ field }) => (
                              <Button
                                key={index}
                                sx={{
                                  width: 40,
                                  height: 40,
                                  minWidth: 40,
                                  padding: 0,
                                }}
                                color="secondary"
                                variant={field.value ? "contained" : undefined}
                                onClick={() => {
                                  field.onChange(!field.value);
                                }}
                              >
                                {day.slice(0, 2)}
                              </Button>
                            )}
                          />
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>
                  <Controller
                    name="mandatory"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={field.value}
                            onChange={(event) => {
                              field.onChange(event.target.checked);
                            }}
                          />
                        }
                        label="Mandatory completion"
                      />
                    )}
                  />
                  <Controller
                    name="shuffle"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!field.value}
                            onChange={(event) => {
                              field.onChange(!event.target.checked);
                            }}
                          />
                        }
                        label="Don't shuffle lessons"
                      />
                    )}
                  />

                  <Collapse in={watch("mandatory")}>
                    <Stack
                      sx={{
                        paddingTop: 2,
                      }}
                      spacing={1}
                    >
                      <Stack direction="row" spacing={2}>
                        <Controller
                          name="startDate"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              label="Start date"
                              value={moment.utc(field.value)}
                              onChange={(newValue) =>
                                field.onChange(
                                  moment.utc(newValue).startOf("day").toDate()
                                )
                              }
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  error: !!errors.startDate,
                                  helperText: errors.startDate?.message,
                                },
                              }}
                            />
                          )}
                        />
                        <Controller
                          name="endDate"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              label="End date"
                              value={
                                field.value ? moment.utc(field.value) : null
                              }
                              minDate={max(
                                moment(watch("startDate")),
                                moment()
                              )}
                              onChange={(newValue) =>
                                field.onChange(
                                  moment.utc(newValue).endOf("day").toDate()
                                )
                              }
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  error: !!errors.endDate,
                                  helperText: errors.endDate?.message,
                                },
                              }}
                            />
                          )}
                        />
                      </Stack>
                      <Stack>
                        <Typography>Correct answers goal:</Typography>
                        <Controller
                          name="correctAnswersCriteria"
                          control={control}
                          render={({ field }) => (
                            <Slider
                              value={field.value}
                              onChange={(_, value) => {
                                field.onChange(value);
                              }}
                              valueLabelDisplay="auto"
                              step={1}
                              marks
                              min={0}
                              max={100}
                            />
                          )}
                        />
                      </Stack>
                    </Stack>
                  </Collapse>
                </Stack>
              </TabPanel>
              <TabPanel value="instant">
                <Stack spacing={1}>
                  <Stack direction="row" spacing={2}>
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          label="Publish date"
                          value={moment.utc(field.value)}
                          onChange={(newValue) =>
                            field.onChange(
                              moment.utc(newValue).startOf("day").toDate()
                            )
                          }
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!errors.startDate,
                              helperText: errors.startDate?.message,
                            },
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          label="Must-complete-by date"
                          value={field.value ? moment.utc(field.value) : null}
                          minDate={max(moment(watch("startDate")), moment())}
                          onChange={(newValue) =>
                            field.onChange(
                              moment.utc(newValue).endOf("day").toDate()
                            )
                          }
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!errors.endDate,
                              helperText: errors.endDate?.message,
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack>
                    <Typography>
                      Number of lessons <InfoCircle />
                    </Typography>
                    <Controller
                      name="lessonCount"
                      control={control}
                      render={({ field }) => (
                        <Slider
                          value={field.value}
                          onChange={(_, value) => {
                            field.onChange(value);
                          }}
                          valueLabelDisplay="auto"
                          step={1}
                          marks
                          min={1}
                          max={25}
                        />
                      )}
                    />
                  </Stack>
                  <Stack>
                    <Typography>Correct answers goal:</Typography>
                    <Controller
                      name="correctAnswersCriteria"
                      control={control}
                      render={({ field }) => (
                        <Slider
                          value={field.value}
                          onChange={(_, value) => {
                            field.onChange(value);
                          }}
                          valueLabelDisplay="auto"
                          step={1}
                          marks
                          min={0}
                          max={100}
                        />
                      )}
                    />
                  </Stack>
                  <Controller
                    name="shuffle"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!field.value}
                            onChange={(event) => {
                              field.onChange(!event.target.checked);
                            }}
                          />
                        }
                        label="Don't shuffle lessons"
                      />
                    )}
                  />
                </Stack>
              </TabPanel>
            </TabContext>
          </Stack>
          <Actions
            neutral={{
              label: "Cancel",
              callback: handleClose,
            }}
            positive={{
              label: "Schedule",
              callback: () => {},
              isLoading: isSubmitting,
              disabled: isDeleting,
            }}
            negative={
              initialValues && {
                label: "Delete",
                callback: onDelete,
                isLoading: isDeleting,
                approve: {
                  title:
                    "Are you sure you want to delete this course distribution?",
                },
              }
            }
          />
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default DistributeCourse;
